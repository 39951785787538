<div class="d-flex flex-column my-2" data-automation-id="f-chat-assistant-message">
  <f-message-label
    [name]="authorName"
    [timestamp]="timestamp"
    data-automation-id="f-chat-message-info"
  ></f-message-label>
  <div class="d-inline-flex" [ngSwitch]="messageStatus">
    <div
      *ngSwitchCase="MessageStatus.Loaded"
      class="px-3 py-2 chat-assistant-message"
      data-automation-id="f-chat-message"
    >
      <ng-container *ngTemplateOutlet="messageContent"></ng-container>
    </div>
    <div
      *ngSwitchCase="MessageStatus.Streaming"
      class="px-3 py-2 chat-assistant-message"
      data-automation-id="f-chat-message"
    >
      <ng-container *ngTemplateOutlet="messageContent"></ng-container>
      <f-loading-dots [dotNumber]="LoadingDotsNumber" rootCssClass="d-inline-block"></f-loading-dots>
    </div>
    <div *ngSwitchCase="MessageStatus.Loading">
      <f-loading-dots rootCssClass="px-3 py-2 chat-assistant-message"></f-loading-dots>
    </div>
    <div *ngSwitchCase="MessageStatus.LoadingUpdate" class="px-3 py-2 chat-assistant-message">
      <ng-content select="[fMessageLoadingUpdate]"></ng-content>
    </div>
    <div *ngSwitchCase="MessageStatus.Failed" class="d-flex" data-automation-id="f-chat-error-message">
      <span class="text-prewrap px-3 py-2 chat-assistant-message chat-error-message">
        <ng-content select="[fMessageError]"></ng-content>
      </span>
      <span class="d-flex align-items-center">
        <i class="f-i f-i-md f-i-warning align-middle chat-error-icon"></i>
      </span>
    </div>
  </div>

  <div
    *ngIf="messageStatus === MessageStatus.Loaded"
    class="d-flex justify-content-end mt-1"
    data-automation-id="f-chat-assistant-message-actions"
  >
    <ng-content select="[fMessageActions]"></ng-content>
  </div>
  <ng-template #messageContent>
    <div #content data-automation-id="f-chat-assistant-message-content">
      <ng-content></ng-content>
    </div>
  </ng-template>
</div>

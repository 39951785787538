import { DraggableItem } from '@capital-access/firefly/components';

export enum ReportSection {
  InstitutionDetails = 'InstitutionDetails',
  InstitutionInvestmentApproach = 'InstitutionInvestmentApproach',
  FinancialHighlights = 'FinancialHighlights',
  Targeting = 'Targeting',
  EquityPortfolio = 'EquityPortfolio',
  HistoricalOwnership = 'HistoricalOwnership',
  ActivityHistory = 'ActivityHistory',
  SustainabilityFocus = 'SustainabilityFocus',
  SuitabilityFactors = 'SuitabilityFactors',
  PeerOwnership = 'PeerOwnership',
  CustomFields = 'CustomFields',
  Style = 'StyleName',
  HolderRegion = 'HolderRegionName',
  HolderInvestmentCenter = 'HolderInvestmentCenterName',
  HolderCountry = 'HolderCountryName',
  HistoricalOwnershipTableDetails = 'HistoricalOwnershipTableDetails',
  FundsSummary = 'FundSummary'
}

export type ConditionalReportSections = Partial<Record<ReportSection, boolean>>;

export interface SectionSelectorListItem extends DraggableItem {
  id: ReportSection;
  isSelected: boolean;
  localizationKey?: string;
  isCommonForAllSections?: boolean;
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { EditorComponent } from '@progress/kendo-angular-editor';
import { ToolBarButtonComponent, ToolBarComponent } from '@progress/kendo-angular-toolbar';
import { handleResizeAltIcon, handleResizeIcon, SVGIcon } from '@progress/kendo-svg-icons';
import { combineLatest, filter, Observable, of, Subscription, takeWhile } from 'rxjs';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { collapsePath, expandPath } from '../kendo-icons/kendo-icons.constants';
import { FireflyLocalizationService } from '../utils/localization/firefly-localization.service';

// TODO: add input property to indicate the type of toolbar
@Component({
  selector: 'f-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyRichTextEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  toolbarWidth = 100;
  pasteCleanupSettings = {
    convertMsLists: true,
    removeHtmlComments: true,
    stripTags: ['script', 'noscript', 'applet', 'embed', 'object', 'param', 'style'],
    removeAttributes: [''],
    removeMsClasses: true,
    removeMsStyles: true,
    removeInvalidHTML: true
  };
  valid: boolean = true;
  collapseIcon = handleResizeIcon;
  expandIcon = handleResizeAltIcon;
  initNotes = '';
  newNotes = '';

  @Input() placeholder: string = '';
  @Input() set value(notes: string) {
    this.newNotes = notes;
    this.initNotes = notes;
  }
  @Input() toolbarOverflow: boolean = true;
  @Input() limit: number = 70000;
  @Input() enableResizing = true;
  @Output() valueChange = new EventEmitter<string>();
  @Output() resizeNotes = new EventEmitter<boolean>();

  @HostBinding('class.k-expanded-editor') isExpanded = false;
  @ViewChild(ToolBarComponent) private toolbar!: ToolBarComponent;
  @ViewChild(EditorComponent) private editor!: EditorComponent;

  @HostListener('window:beforeunload')
  triggerConfirmationLeavePopup(e: BeforeUnloadEvent): void {
    if (this.newNotes !== this.initNotes) e.preventDefault();
  }

  editorFontSize = 'Size';
  editorPlaceholder = 'Add notes';
  localizationIsReady = false;
  editorCharactersLimit$: Observable<unknown> = of('Characters limit exceeded');
  editorCollapseTitle$: Observable<string> = of('Collapse editor');
  editorExpandTitle$: Observable<string> = of('Expand editor');

  private _subs = new Subscription();
  private store = inject(Store, { optional: true });

  constructor(
    private zone: NgZone,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    @Optional() private localizationService: FireflyLocalizationService
  ) {}

  get resizeIcon(): SVGIcon {
    return this.isExpanded ? this.collapseIcon : this.expandIcon;
  }

  get resizeTitle$(): Observable<string> {
    return this.isExpanded ? this.editorCollapseTitle$ : this.editorExpandTitle$;
  }

  ngOnInit() {
    this._subs.add(
      this.store
        ?.select(getFeatureToggle('CPD-1877-expanded-notes-rich-text-editor'))
        .subscribe(isResizingAvailable => (this.enableResizing = this.enableResizing && isResizingAvailable))
    );

    this.expandIcon.content = expandPath;
    this.collapseIcon.content = collapsePath;
    // workaround for on push change detection with kendo-editor issue: https://github.com/telerik/kendo-angular/issues/3998
    this._subs.add(this.zone.onMicrotaskEmpty.subscribe(() => this.cdr.markForCheck()));

    if (!this.localizationService) {
      this.localizationIsReady = true;
      return;
    }
    if (this.localizationService) {
      this.editorCharactersLimit$ = this.localizationService.localize('editorCharactersLimit', {});
      this.editorCollapseTitle$ = this.localizationService.localize('editorCollapseTitle', {});
      this.editorExpandTitle$ = this.localizationService.localize('editorExpandTitle', {});
    }
    this._subs.add(
      combineLatest([
        this.localizationService?.localize('editorFontSize', {}),
        this.localizationService?.localize('editorPlaceholder', {})
      ]).subscribe(([editorFontSize, editorPlaceholder]) => {
        this.editorFontSize = editorFontSize;
        this.editorPlaceholder = editorPlaceholder;
        this.localizationIsReady = true;
        this.cdr.detectChanges();
      })
    );
  }

  ngAfterViewInit() {
    if (this.toolbarOverflow) {
      // workaround for proper toolbar size on editor open https://github.com/telerik/kendo-angular/issues/4085
      // call `onResize` once Zone is stable and overflow button is overflowed by `toolbarWidth`,
      // whether it is visible or hidden to make toolbar init it's size properly
      this._subs.add(
        this.zone.onStable
          .pipe(
            // `onResize` could need to be called several times during Zone has pending macrotasks to achieve proper
            // size initialization for toolbar (this case takes place at least in Storybook)
            takeWhile(() => this.zone.hasPendingMacrotasks),
            filter(() => this.isOverflowButtonOverflowed())
          )
          .subscribe(() => this.zone.runTask(this.toolbar.onResize, this.toolbar))
      );
    }
  }

  isOverflowButtonOverflowed(): boolean {
    const overflowButton = this.toolbar.overflowButton.nativeElement;
    // offsetParent right padding could not be taken into account because `onResize` function does not lead to resize
    // but it leads to stack overflow in that case
    return overflowButton.offsetLeft + overflowButton.offsetWidth >= overflowButton.offsetParent.offsetWidth;
  }

  onValueChange(value: string) {
    this.newNotes = value;
    this.valid = value.length < this.limit;
    this.valueChange.emit(value);
  }

  @HostListener('document:keydown', ['$event'])
  onDocumentKeyDown(event: KeyboardEvent) {
    if (event.key === 'Tab' && event.shiftKey) {
      const isEditorFocused = (event.target as HTMLElement)?.id.includes('k-editor');
      if (isEditorFocused) {
        requestAnimationFrame(() => {
          (this.toolbar.allTools.first as ToolBarButtonComponent).toolbarButtonElement.nativeElement.focus();
        });
      }
    }
  }

  onResizeNotes() {
    const el = document.querySelector('.ca-content');

    this.isExpanded = !this.isExpanded;
    this.resizeNotes.emit(this.isExpanded);

    if (this.isExpanded) {
      el!.scrollTop = 0;
      this.renderer.addClass(el, 'overflow-hidden');
    } else {
      this.renderer.removeClass(el, 'overflow-hidden');
    }

    // workaround to remove the focused state from the first button in the toolbar
    // approach with applying focus on editor leads to unpredictable page movement on virtual keyboard appearance event
    // both calls should be inside `requestAnimationFrame` to avoid movement of the browser toolbar at the bottom of the mobile browser
    requestAnimationFrame(() => {
      this.editor.focus();
      this.editor.blur();
    });
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { getUserSettingsCulture, getUserSettingsTimezone } from '@capital-access/common/globalization';
import {
  getSelectedSecurity,
  getSelectedSecurityCompanyName,
  getSelectedSecuritySurveillancePermission
} from '@capital-access/common/security';
import { createHistoricalOwnershipReportFinished } from '@capital-access/reporting/common';
import { generateReport } from '../../core/+state/report.actions';
import { FileNameService } from '../../core/services/file-name.service';
import { createHistoricalOwnershipReport } from '../+state/historical-ownership-report.actions';
import { DEFAULT_FILE_NAME } from '../constants';
import { CreateHistoricalOwnershipRequest } from '../models/historical-ownership-report.models';

@Injectable()
export class HistoricalOwnershipReportEffects {
  createHistoricalOwnershipReportRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createHistoricalOwnershipReport),
      withLatestFrom(
        this.store.select(getSelectedSecurity),
        this.store.select(getSelectedSecurityCompanyName),
        this.store.select(getUserSettingsTimezone),
        this.store.select(getSelectedSecuritySurveillancePermission),
        this.store.select(getFeatureToggle('surveillance-across-application-cora-2613')),
        this.store.select(getFeatureToggle('skyl-2231-culture-specific-formatting')),
        this.store.select(getUserSettingsCulture)
      ),
      mergeMap(
        ([
          { sections, includeTables, reportedQuarters, filters },
          security,
          companyName,
          timeZone,
          isSrvPermissioned,
          isSrvFtEnabled,
          cultureFtEnabled,
          culture
        ]) => {
          const isSrv = security?.surveillance ?? false;
          return this.fileNameService
            .getDefaultFileName(isSrvPermissioned && isSrvFtEnabled, isSrv, companyName ?? DEFAULT_FILE_NAME, timeZone)
            .pipe(
              map(fileName => {
                const createReportRequest: CreateHistoricalOwnershipRequest = {
                  sections,
                  timeZone,
                  securityId: security?.id,
                  fileName,
                  culture: cultureFtEnabled ? culture : 'en-US',
                  includeTables,
                  reportedQuarters,
                  filters
                };

                return generateReport({
                  request: createReportRequest,
                  url: 'historical-ownership-reports/process/launch',
                  successMessage: 'historicalOwnershipReportCreated',
                  failureMessage: 'historicalOwnershipReportFailed',
                  finishAction: createHistoricalOwnershipReportFinished()
                });
              })
            );
        }
      )
    )
  );

  constructor(private actions$: Actions, private store: Store, private fileNameService: FileNameService) {}
}

import { createAction, props } from '@ngrx/store';
import { AiComponentData } from '../models/chat-event';
import { ChatMessage } from '../models/chat-message';

export const loadAiPanel = createAction('[AI Panel] Load AI Panel');
export const openDrawer = createAction('[AI Panel] Open Drawer');

export const openDisclaimer = createAction('[AI Panel] Open Disclaimer');

export const submitChat = createAction('[AI Panel] Submit Chat', props<{ content: string; timestamp: string }>());
export const submitChatFailure = createAction('[AI Panel] Submit Chat Failure', props<{ error: any }>());

export const startStreaming = createAction('[AI Panel] Start Streaming');

export const streamingResponseStart = createAction(
  '[AI Panel] Streaming Chat Response Start',
  props<{ messageId: string; timestamp: string }>()
);
export const streamingResponseToken = createAction(
  '[AI Panel] Streaming Chat Response Token',
  props<{ messageId: string; content: string; timestamp: string }>()
);
export const streamingResponseEnd = createAction(
  '[AI Panel] Streaming Chat Response End',
  props<{ messageId: string; timestamp: string }>()
);

export const streamingFunctionCallStart = createAction(
  '[AI Panel] Streaming Function Call Start',
  props<{ messageId: string; content: string }>()
);
export const streamingFunctionCallEnd = createAction(
  '[AI Panel] Streaming Function Call End',
  props<{ messageId: string; content: string; fnParams?: Record<string, object>; timestamp: string }>()
);

export const streamingTableComponent = createAction(
  '[AI Panel] Streaming Table Result',
  props<{ messageId: string; componentData: AiComponentData; timestamp: string }>()
);

export const createNewChat = createAction('[AI Panel] Create New Chat');
export const newChatSuccess = createAction('[AI Panel] New Chat Success', props<{ id: string }>());
export const newChatFailure = createAction('[AI Panel] New Chat Failure', props<{ error: any }>());

export const openChatHistory = createAction('[AI Panel] Open Chat History');
export const openChatHistorySuccess = createAction(
  '[AI Panel] Open Chat History Success',
  props<{ conversations: ChatMessage[] }>()
);
export const openChatHistoryFailure = createAction('[AI Panel] Open Chat History Failure', props<{ error: any }>());

export const openChat = createAction('[AI Panel] Open Chat', props<{ chatId: string; content: string }>());
export const openChatSuccess = createAction('[AI Panel] Open Chat Success', props<{ chatHistory: ChatMessage[] }>());
export const openChatFailure = createAction('[AI Panel] Open Chat Failure', props<{ error: any }>());

export const addToInstitutionList = createAction('[AI Panel] Add To Institution List', props<{ ids: any }>());

export const messageFeedback = createAction(
  '[AI Panel] Message Feedback',
  props<{ messageId: string; isLiked: boolean }>()
);
export const messageFeedbackSuccess = createAction(
  '[AI Panel] Message Feedback Success',
  props<{ messageId: string; isLiked: boolean }>()
);
export const messageFeedbackFailure = createAction(
  '[AI Panel] Message Feedback Failure',
  props<{ messageId: string; error: any }>()
);

export const openConfirmationModal = createAction(
  '[AI Panel] Open Confirmation Modal',
  props<{ chatId: string; content: string }>()
);
export const deleteChat = createAction('[AI Panel] Delete Chat', props<{ chatId: string; content: string }>());
export const deleteChatSuccess = createAction(
  '[AI Panel] Delete Chat Success',
  props<{ chatId: string; content: string }>()
);
export const deleteChatFailure = createAction(
  '[AI Panel] Delete Chat Failure',
  props<{ content: string; error: any }>()
);

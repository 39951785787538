import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { ChipSize } from '../models/chip-size';
import { ChipType } from '../models/chip-type';

@Component({
  selector: 'f-single-choice-chip-group',
  templateUrl: './single-choice-chip-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflySingleChoiceChipGroupComponent implements OnChanges {
  @Input() items: Record<string, unknown>[] | null = [];
  @Input() valuePath: string = 'text';
  @Input() idField: string = 'id';
  @Input() dropdownPlacement: string = 'bottom-right';
  @Input() chipSize = ChipSize.Xs;
  @Input() rootCssClass!: string;
  @Input() selectedId: unknown;
  @Input() disabled!: boolean;
  @Input() error!: boolean;
  @Input() responsive = false;
  @Input() outline = false;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() select = new EventEmitter<unknown>();

  selectedItem!: Record<string, unknown> | undefined;
  iconPopoverContent = '';
  private selectedItemHasIcon = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedId?.currentValue !== changes.selectedId?.previousValue) {
      this.selectedItem = this.items?.find(item => item[this.idField] === this.selectedId);
      if (this.selectedItem) {
        this.selectedItemHasIcon = this.selectedItem.hasIcon as boolean;
        this.iconPopoverContent = this.selectedItem.iconPopoverContent as string;
      }
    }
  }

  onSelect(item: Record<string, unknown>) {
    this.select.emit(item);
  }

  getChipType(item: Record<string, unknown>) {
    return item[this.idField] === this.selectedId
      ? this.error
        ? ChipType.EditableDanger
        : ChipType.SingleChoice
      : ChipType.Enabled;
  }

  get btnStyle() {
    const btnTypeClasses = `${this.outline ? 'btn-outline' : 'btn-secondary'} ${
      this.selectedItemHasIcon ? 'btn-with-text ps-2' : ''
    }`;
    return `${btnTypeClasses} ${this.getBtnSize(this.chipSize)}`;
  }

  get iconAfterTitle() {
    return this.selectedItemHasIcon ? `f-i-xs f-i-info ms-1` : '';
  }

  private getBtnSize(size: ChipSize) {
    switch (size) {
      case ChipSize.Xs:
        return 'btn-sm';
      case ChipSize.Sm:
        return 'py-1';
      default:
        return '';
    }
  }
}

import { ProfileType } from '@capital-access/profiles/common';
import { ConditionalReportSections, ReportSection, SectionSelectorListItem } from '../../core/models/report-sections';

export function getConditionalReportSections(
  isCustomFieldsToggleEnabled: boolean,
  isFundsSummaryEnabled: boolean,
  isHistoricalOwnershipToggleEnabled: boolean
): ConditionalReportSections {
  return {
    [ReportSection.CustomFields]: isCustomFieldsToggleEnabled,
    [ReportSection.FundsSummary]: isFundsSummaryEnabled,
    [ReportSection.HistoricalOwnership]: isHistoricalOwnershipToggleEnabled
  };
}

const DEFAULT_CONTACT_SECTIONS: SectionSelectorListItem[] = [
  {
    id: ReportSection.InstitutionDetails,
    isSelected: true,
    localizationKey: 'primaryInstitutionDetailsSection'
  },
  {
    id: ReportSection.InstitutionInvestmentApproach,
    isSelected: true,
    localizationKey: 'primaryInstitutionInvestmentApproachSection'
  },
  { id: ReportSection.CustomFields, isSelected: true, localizationKey: 'customFieldsSection' },
  { id: ReportSection.FundsSummary, isSelected: true, localizationKey: 'fundsSummarySection' },
  {
    id: ReportSection.Targeting,
    isSelected: true,
    localizationKey: 'targetingSection'
  },
  {
    id: ReportSection.FinancialHighlights,
    isSelected: true,
    localizationKey: 'ownershipActivitiesSection'
  },
  {
    id: ReportSection.EquityPortfolio,
    isSelected: true,
    localizationKey: 'primaryInstitutionEquityPortfolioSection'
  },
  {
    id: ReportSection.PeerOwnership,
    isSelected: true,
    localizationKey: 'primaryInstitutionPeerOwnershipSection'
  },
  {
    id: ReportSection.SuitabilityFactors,
    isSelected: true,
    localizationKey: 'suitabilityFactors'
  },
  {
    id: ReportSection.HistoricalOwnership,
    isSelected: true,
    localizationKey: 'primaryInstitutionHistoricalOwnershipSection'
  },
  { id: ReportSection.ActivityHistory, isSelected: true, localizationKey: 'activityHistorySection' },
  { id: ReportSection.SustainabilityFocus, isSelected: true, localizationKey: 'sustainabilityFocusSection' }
];

const DEFAULT_INVESTOR_SECTIONS: SectionSelectorListItem[] = [
  { id: ReportSection.InstitutionDetails, isSelected: true, localizationKey: 'institutionDetailsSection' },
  {
    id: ReportSection.InstitutionInvestmentApproach,
    isSelected: true,
    localizationKey: 'institutionInvestmentApproachSection'
  },
  { id: ReportSection.CustomFields, isSelected: true, localizationKey: 'customFieldsSection' },
  { id: ReportSection.FundsSummary, isSelected: true, localizationKey: 'fundsSummarySection' },
  {
    id: ReportSection.Targeting,
    isSelected: true,
    localizationKey: 'targetingSection'
  },
  {
    id: ReportSection.FinancialHighlights,
    isSelected: true,
    localizationKey: 'ownershipActivitiesSection'
  },
  { id: ReportSection.EquityPortfolio, isSelected: true, localizationKey: 'institutionEquityPortfolioSection' },
  { id: ReportSection.PeerOwnership, isSelected: true, localizationKey: 'institutionPeerOwnershipSection' },
  { id: ReportSection.SuitabilityFactors, isSelected: true, localizationKey: 'suitabilityFactors' },
  { id: ReportSection.HistoricalOwnership, isSelected: true, localizationKey: 'historicalOwnershipSection' },
  { id: ReportSection.ActivityHistory, isSelected: true, localizationKey: 'activityHistorySection' },
  { id: ReportSection.SustainabilityFocus, isSelected: true, localizationKey: 'sustainabilityFocusSection' }
];

export function getDefaultSections(profileType: ProfileType): SectionSelectorListItem[] {
  return profileType == ProfileType.Contact ? DEFAULT_CONTACT_SECTIONS : DEFAULT_INVESTOR_SECTIONS;
}

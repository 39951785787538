<div class="empty-state-wrapper">
  <div data-automation-id="empty-state-body" class="empty-state-body">
    <div
      data-automation-id="simple-actionable-empty-state-img"
      class="simple-actionable-empty-state-img"
      [ngClass]="simpleActionableOption"
    ></div>
    <div data-automation-id="simple-actionable-empty-state-caption" class="simple-actionable-empty-state-caption">
      {{ caption }}
    </div>
    <div data-automation-id="simple-actionable-empty-state-info" class="simple-actionable-empty-state-info">
      {{ info }}
    </div>
    <ng-content select="[fEmptyStatesActions]"></ng-content>
  </div>
  <div data-automation-id="empty-state-note" class="empty-state-note">{{ note }}</div>
</div>
